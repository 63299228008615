import { useRef, useState } from "react";
import { X } from "lucide-react";
import { toast } from "react-toastify";

interface customerModal {
  isOpen: boolean;
  onClose: Function;
  onSave: Function;
}

export default function CustomerModal({
  isOpen,
  onClose,
  onSave,
}: customerModal) {
  const modalRef = useRef<HTMLDivElement>(null);

  const [customerData, setcustomerData] = useState({
    name: "",
    email: "",
    number: "",
    wtsAppNumber: "",
    company_address: "",
    point_of_contact: "",
  });

  // validations
  const [nameError, setnameError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [numberError, setnumberError] = useState(false);
  const [wtsAppError, setWtsAppError] = useState(false);
  const [addError, setaddError] = useState(false);
  const [pocError, setpocError] = useState(false);
  const [showData, setShowData] = useState(false);
  const [addedContacts, setAddedContacts] = useState<
    {
      point_of_contact: string;
      email: string;
      number: string;
      wtsAppNumber: string;
    }[]
  >([]);

  const handleInputChange = (field: any, e: string) => {
    setcustomerData((prev) => ({
      ...prev,
      [field]: e,
    }));
  };

  const AddMoreFunction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;

    // Validate inputs only if fields are filled
    if (
      customerData.point_of_contact ||
      customerData.email ||
      customerData.number ||
      customerData.wtsAppNumber
    ) {
      if (!customerData.point_of_contact) {
        setpocError(true);
        toast("Please enter point of contact");
        return;
      }
      if (!emailRegex.test(customerData.email)) {
        setemailError(true);
        toast("Please enter a valid email");
        return;
      }
      if (customerData.number.length !== 10) {
        setnumberError(true);
        toast("Please enter a valid 10-digit contact number");
        return;
      }
      if (customerData.wtsAppNumber.length !== 10) {
        setWtsAppError(true);
        toast("Please enter a valid 10-digit WhatsApp number");
        return;
      }

      if (addedContacts.length >= 5) {
        toast("You can only add up to 5 contacts.");
        return;
      }

      // Add new contact details to the list
      const newContact = {
        point_of_contact: customerData.point_of_contact,
        email: customerData.email,
        number: customerData.number,
        wtsAppNumber: customerData.wtsAppNumber,
      };

      setAddedContacts((prev) => [...prev, newContact]);

      // Clear only specific fields
      setcustomerData((prev) => ({
        ...prev,
        point_of_contact: "",
        email: "",
        number: "",
        wtsAppNumber: "",
      }));
      setShowData(true);
    } else {
      toast("Please fill out at least one field before adding more.");
    }
  };

  const saveCustomerData = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (customerData.name === "") {
      setnameError(true);
    } else if (customerData.point_of_contact === "") {
      setpocError(true);
      toast("Please add at least one contact.");
    } else if (
      !emailRegex.test(customerData.email) &&
      customerData.email !== ""
    ) {
      setemailError(true);
    } else if (customerData.number.length !== 10) {
      setnumberError(true);
    } else if (customerData.wtsAppNumber.length !== 10) {
      setWtsAppError(true);
    } else if (customerData.company_address === "") {
      setaddError(true);
    } else {
      onSave({ customerData: customerData, contactArray: addedContacts });
      resetCustomerData();
      setAddedContacts([]);
    }
  };

  const resetCustomerData = () => {
    setcustomerData({
      name: "",
      email: "",
      number: "",
      wtsAppNumber: "",
      company_address: "",
      point_of_contact: "",
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={() => {
            onClose(false), resetCustomerData();
          }}
        />
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          ref={modalRef}
          className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all w-full max-w-xs sm:max-w-lg md:max-w-4xl sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          tabIndex={-1}
        >
          {/* Modal Header */}
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative">
            <h3
              className="text-lg font-medium leading-6 text-gray-900"
              id="modal-headline"
            >
              Add new customer
            </h3>
            <button
              onClick={() => {
                onClose(false), resetCustomerData();
              }}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5"
            >
              <X className="w-5 h-5" />
            </button>

            {/* Form Section */}
            <form className="mt-6 space-y-6">
              {/* Customer Name */}
              <div>
                <label
                  htmlFor="company-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Customer/Company name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="company-name"
                  className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  placeholder="Enter company name"
                  value={customerData.name}
                  onChange={(e) => {
                    handleInputChange("name", e.target.value),
                      setnameError(false);
                  }}
                />
                {nameError && <Validation name={"please enter company name"} />}
              </div>

              {/* Contact Details */}
              <div className="flex justify-between items-center ">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 items-center">
                  {/* Company Point of Contact */}
                  <div>
                    <label
                      htmlFor="contact-person"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company point of contact{" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      id="contact-person"
                      className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      placeholder="Point contact number"
                      value={customerData.point_of_contact}
                      onChange={(e) => {
                        handleInputChange("point_of_contact", e.target.value),
                          setpocError(false);
                      }}
                    />
                    {pocError && (
                      <Validation name={"please enter point of contact"} />
                    )}
                  </div>

                  {/* Email */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      placeholder="Enter email id"
                      value={customerData.email}
                      onChange={(e) => {
                        handleInputChange("email", e.target.value);
                        setemailError(false);
                      }}
                    />
                    {emailError && (
                      <Validation name={"please enter valid email"} />
                    )}
                  </div>

                  {/* Contact Number */}
                  <div>
                    <label
                      htmlFor="contact-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Contact number <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="tel"
                      id="contact-number"
                      className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      placeholder="Enter Contact number"
                      value={customerData.number}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setnumberError(false);
                        if (/^\d*$/.test(inputValue)) {
                          handleInputChange("number", inputValue);
                        }
                      }}
                    />
                    {numberError && (
                      <Validation name={"please enter 10 digit valid number"} />
                    )}
                  </div>

                  {/* WhatsApp Number */}
                  <div>
                    <label
                      htmlFor="whatsApp-number"
                      className="text-sm font-medium text-gray-700 flex items-center"
                    >
                      WhatsApp Number <span className="text-red-600">*</span>
                      <img
                        src="https://img.icons8.com/3d-fluency/94/whatsapp-logo.png"
                        alt="WhatsApp logo"
                        className="ml-2"
                        width="20"
                        height="20"
                      />
                    </label>
                    <input
                      type="tel"
                      id="whatsApp-number"
                      className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      placeholder="Enter WhatsApp number"
                      value={customerData.wtsAppNumber}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setWtsAppError(false);
                        if (/^\d*$/.test(inputValue)) {
                          handleInputChange("wtsAppNumber", inputValue);
                        }
                      }}
                    />
                    {wtsAppError && (
                      <Validation name={"please enter 10 digit valid number"} />
                    )}
                  </div>
                </div>
                <button
                  onClick={(e) => AddMoreFunction(e)}
                  className="text-sky-500 hover:text-sky-300 mt-5"
                >
                  Add More
                </button>
              </div>

              {showData &&
                addedContacts.map((contact, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center w-[90%] px-2 py-1 bg-gray-100 rounded-md"
                  >
                    <p>{contact.point_of_contact}</p>
                    <p>{contact.email}</p>
                    <p>{contact.number}</p>
                    <p>{contact.wtsAppNumber}</p>
                    <button
                      onClick={() =>
                        setAddedContacts((prev) =>
                          prev.filter((_, i) => i !== index)
                        )
                      }
                      className="text-red-500 hover:text-red-700"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                ))}

              {/* Company Address */}
              <div>
                <label
                  htmlFor="company-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company address <span className="text-red-600">*</span>
                </label>
                <textarea
                  id="company-address"
                  rows={3}
                  className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  placeholder="Write here"
                  value={customerData.company_address}
                  onChange={(e) => {
                    handleInputChange("company_address", e.target.value);
                    setaddError(false);
                  }}
                />
                {addError && <Validation name={"please enter address"} />}
              </div>
            </form>
          </div>

          {/* Submit Button */}
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              onClick={() => saveCustomerData()}
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

interface props {
  name: string;
}

const Validation = ({ name }: props) => {
  return (
    <label className="text-[11px] ml-1 absolute text-red-500">{name}</label>
  );
};
