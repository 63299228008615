import { useEffect, useState } from "react";
import SideDrawer from "../components/SideDrawer";
import CustomHeader from "../components/CustomHeader";
import ApiManager from "../api/ApiManager";
import Spinner from "../components/Spinner";
import { useLocation, useNavigate } from "react-router-dom";
import CreatePhases from "../components/CreatePhases";

interface PhaseData {
  start_date: string;
  end_date: string;
  description: string;
  image: string;
  phaseType: string;
}

const CreateProject = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const customerId = location.state?.customerId;
  const customerName = location.state?.customerName;

  const [newProject, setnewProject] = useState({
    projectName: "",
    InternalSrNo: "",
    externalSrNo: "",
    typeOfTrans: "",
    rating: "",
    kvClass: "",
  });

  const [phaseData, setphaseData] = useState<any>([]);

  const [phaseNumber, setphaseNumber] = useState(1);
  const [showPhases, setshowPhases] = useState(false);
  const [apiLoader, setapiLoader] = useState(false);

  const [nameError, setnameError] = useState(false);
  const [intSrNoError, setintSrNoError] = useState(false);
  const [transError, settransError] = useState(false);
  const [ratingError, setratingError] = useState(false);
  const [kvError, setkvError] = useState(false);

  useEffect(() => {
    const disableRightClick = (e: { preventDefault(): any }) => {
      e.preventDefault();
      document.addEventListener("contextmenu", disableRightClick);
    };
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  });

  const createNewProject = async (data: any) => {
    setapiLoader(true);

    const body = {
      name: newProject.projectName,
      internal_sr_no: newProject.InternalSrNo,
      external_sr_no: newProject.externalSrNo ? newProject.externalSrNo : "",
      customerId: customerId,
      specs: [
        { key: "typeOfTrans", value: newProject.typeOfTrans },
        { key: "rating", value: newProject.rating },
        { key: "kvClass", value: newProject.kvClass },
      ],
    };

    try {
      const resp = await ApiManager.createProject(body);

      if (resp?.data?.status === true) {
        const projectId = resp?.data.message.projectId;
        for (const item of data) {
          await createNewPhase(item, projectId);
        }
        navigate(-1);
      }
    } catch (error) {
      console.error("Error creating project:", error);
    } finally {
      setapiLoader(false);
    }
  };

  let phaseCount = 0;
  const createNewPhase = async (item: any, projectId: any) => {
    let formdata = new FormData();
    formdata.append("projectId", projectId);
    formdata.append("name", item.phaseType);
    formdata.append("start_date", item.start_date);
    formdata.append("end_date", item.end_date);
    formdata.append("description", item.description);
    formdata.append("image", item.image !== null ? item.image : "");
    formdata.append("dateCount", "1");
    formdata.append("phaseCount", JSON.stringify(phaseCount + 1));

    phaseCount = phaseCount + 1;

    try {
      const resp = await ApiManager.createPhase(formdata);
      if (resp?.data?.status == true) {
        console.log("Phase created:", resp.data);
      }
    } catch (error) {
      console.error("Error creating phase:", error);
    }
  };

  const validCreateProject = () => {
    if (newProject.projectName == "") {
      setnameError(true);
    } else if (newProject.InternalSrNo == "") {
      setintSrNoError(true);
    }
    // else if (newProject.externalSrNo == "") {
    //   setextSrNoError(true);
    // }
    else if (newProject.typeOfTrans == "") {
      settransError(true);
    } else if (newProject.rating == "") {
      setratingError(true);
    } else if (newProject.kvClass == "") {
      setkvError(true);
    } else {
      setshowPhases(true);
    }
  };

  const handleProjectInput = (field: any, e: string) => {
    setnewProject((prev) => ({
      ...prev,
      [field]: e,
    }));
  };

  const mergeData = (e: any, f: string) => {
    let isPhase: PhaseData[] = [...phaseData];
    const data: PhaseData = {
      start_date: e.start_date,
      end_date: e.end_date,
      description: e.description,
      image: e.image,
      phaseType: f,
    };
    isPhase.push(data);
    setphaseData(isPhase);

    if (isPhase.length > 4) {
      createNewProject(isPhase);
    }
  };

  const createPhases = () => {
    switch (phaseNumber) {
      case 1:
        return (
          <CreatePhases
            phaseNumber={phaseNumber}
            setPhaseNumber={(e: number) => setphaseNumber(e)}
            phaseType={"Winding"}
            getData={(e: any) => mergeData(e, "Winding")}
          />
        );
      case 2:
        return (
          <CreatePhases
            phaseNumber={phaseNumber}
            setPhaseNumber={(e: number) => setphaseNumber(e)}
            phaseType={"CCA"}
            getData={(e: any) => mergeData(e, "CCA")}
          />
        );
      case 3:
        return (
          <CreatePhases
            phaseNumber={phaseNumber}
            setPhaseNumber={(e: number) => setphaseNumber(e)}
            phaseType={"Tanking"}
            getData={(e: any) => mergeData(e, "Tanking")}
          />
        );
      case 4:
        return (
          <CreatePhases
            phaseNumber={phaseNumber}
            setPhaseNumber={(e: number) => setphaseNumber(e)}
            phaseType={"Testing"}
            getData={(e: any) => mergeData(e, "Testing")}
          />
        );
      case 5:
        return (
          <CreatePhases
            phaseNumber={phaseNumber}
            setPhaseNumber={(e: number) => setphaseNumber(e)}
            phaseType={"Dispatch"}
            getData={(e: any) => mergeData(e, "Dispatch")}
          />
        );

      default:
        break;
    }
  };

  return (
    <div className="flex-1 flex flex-col h-screen">
      <CustomHeader />
      <div className="flex h-screen bg-gray-100">
        <SideDrawer />

        {/* Ensure only main scrolls */}
        <main className="flex-1 overflow-y-auto bg-gray-100 p-4 md:p-6">
          <div className="text-gray-600 mb-2">
            Dashboard &gt;
            <span className="font-semibold"> Customers &gt;</span>
            <span className="font-semibold"> {customerName} &gt;</span>
            <span className="font-semibold"> Create project </span>
          </div>
          <p className="text-sm text-gray-600 mb-4">
            Track your projects with ease in just few clicks
          </p>

          {!showPhases ? (
            <div className="mx-auto bg-white rounded-lg shadow-md p-4 md:p-6 max-w-md md:max-w-4xl">
              <h2 className="text-lg md:text-xl font-semibold mb-4">
                Create project
              </h2>

              <div className="space-y-4">
                {/* Responsive form fields */}
                <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Project name <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="Generator 5"
                      value={newProject.projectName}
                      onChange={(e) => {
                        handleProjectInput("projectName", e.target.value);
                        setnameError(false);
                      }}
                    />
                    {nameError && (
                      <Validation name={"Please enter project name"} />
                    )}
                  </div>
                </div>

                {/* Next row */}
                <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Serial number (internal){" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="ABC1243"
                      value={newProject.InternalSrNo}
                      onChange={(e) => {
                        handleProjectInput("InternalSrNo", e.target.value);
                        setintSrNoError(false);
                      }}
                    />
                    {intSrNoError && (
                      <Validation name={"Please enter serial number"} />
                    )}
                  </div>
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Serial number (external)
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="ABC1243"
                      value={newProject.externalSrNo}
                      onChange={(e) => {
                        handleProjectInput("externalSrNo", e.target.value);
                      }}
                    />
                    {/* {extSrNoError && (
                      <Validation name={"Please enter serial number"} />
                    )} */}
                  </div>
                </div>

                {/* Next row */}
                <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Type of transformer{" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="trans. 1"
                      value={newProject.typeOfTrans}
                      onChange={(e) => {
                        handleProjectInput("typeOfTrans", e.target.value);
                        settransError(false);
                      }}
                    />
                    {transError && (
                      <Validation name={"Please enter type of transformer"} />
                    )}
                  </div>
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Rating <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="abc1243"
                      value={newProject.rating}
                      onChange={(e) => {
                        handleProjectInput("rating", e.target.value);
                        setratingError(false);
                      }}
                    />
                    {ratingError && <Validation name={"Please enter rating"} />}
                  </div>
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Quantity <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="abc1243"
                      value={newProject.kvClass}
                      onChange={(e) => {
                        handleProjectInput("kvClass", e.target.value);
                        setkvError(false);
                      }}
                    />
                    {kvError && <Validation name={"Please enter quantity"} />}
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    onClick={() => validCreateProject()}
                    className="w-full md:w-auto px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                  >
                    Create phases
                  </button>
                </div>
              </div>
            </div>
          ) : (
            createPhases()
          )}
        </main>
      </div>
      <Spinner loading={apiLoader} />
    </div>
  );
};

export default CreateProject;

interface props {
  name: string;
}

const Validation = ({ name }: props) => {
  return <label className="text-[11px] text-red-500">{name}</label>;
};
