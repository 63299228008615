import { useEffect, useState } from "react";
import SideDrawer from "../components/SideDrawer";
import CustomHeader from "../components/CustomHeader";
import { MdAdd, MdDashboard } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import ApiManager from "../api/ApiManager";
import Spinner from "../components/Spinner";
import { ChevronDown, ChevronUp } from "lucide-react";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../components/DeleteModal";
import { toast } from "react-toastify";
import BackButtonComp from "../components/BackButtonComp";

const customerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const customerId = location.state?.customerId;
  const customerName = location.state?.customerName;
  const [projectList, setprojectList] = useState<any>([]);
  const [oldData, setoldData] = useState<any>([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const [sortWithName, setsortWithName] = useState(false);
  const [apiLoader, setapiLoader] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);

  useEffect(() => {
    const disableRightClick = (e: { preventDefault: () => any }) =>
      e.preventDefault();
    document.addEventListener("contextmenu", disableRightClick);
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  useEffect(() => {
    getProjectList();
  }, []);

  const getProjectList = () => {
    setapiLoader(true);
    const body = {
      id: customerId,
    };

    ApiManager.getProject(body)
      .then((resp) => {
        if (resp?.data?.status == true) {
          setprojectList(resp?.data?.data);
        } else {
        }
        setapiLoader(false);
      })
      .catch((error) => {
        console.log("error", error), setapiLoader(false);
      });
  };

  const handleSort = () => {
    setsortWithName(!sortWithName);
    setoldData(projectList);
    const sortedArray = [...projectList].sort((a, b) =>
      a.name.localeCompare(b.customerName)
    );
    setprojectList(sortedArray);
  };

  const handleSort1 = () => {
    setsortWithName(!sortWithName);
    setprojectList(oldData);
  };

  const deleteProject = () => {
    if (!selectedProjectId) return;
    const body = {
      id: selectedProjectId,
    };

    ApiManager.deleteProject(body).then((resp) => {
      if (resp?.data?.status === true) {
        toast.success(resp?.data?.message);
        setdeleteModal(false);
        setprojectList((prevList: any[]) =>
          prevList.filter(
            (project: { id: any }) => project.id !== selectedProjectId
          )
        );

        // Fetch the updated project list
        getProjectList();
      } else {
        toast.error(resp?.data?.message);
      }
    });
  };

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <CustomHeader />
      <div className="flex h-screen bg-gray-100">
        <SideDrawer />

        {/* ---------------- */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          <BackButtonComp />
          <div className="text-gray-600 mb-2">
            Dashboard &gt;
            <span className="font-semibold"> Customers &gt;</span>
            <span className="font-semibold"> {customerName}</span>
          </div>
          <p className="text-sm text-gray-600 mb-4">
            Track your projects with ease in just few clicks
          </p>

          <div className="flex flex-col md:flex-row justify-between items-center mb-4 gap-6">
            <div className="bg-white rounded-lg shadow p-4 flex items-center w-full h-30 min-h-[100px] md:min-h-[120px]">
              <div className="mr-4">
                <div className="text-black text-sm">Total projects</div>
                <div className="text-3xl font-bold">{projectList.length}</div>
              </div>
              <div className="ml-auto">
                <MdDashboard size={50} className="text-gray-400" />
              </div>
            </div>

            <div className="bg-white rounded-lg shadow px-4 py-6 flex items-center w-full h-30 justify-between min-h-[100px] md:min-h-[120px]">
              <div className="text-black font-bold text-lg">
                Create new project
              </div>
              <button
                onClick={() =>
                  navigate("/createProject", {
                    state: {
                      customerId: customerId,
                      customerName: customerName,
                    },
                  })
                }
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                Create <MdAdd size={20} className="ml-1" />
              </button>
            </div>
          </div>

          {/* Customer Table */}
          <span className="font-bold">Projects</span>
          <div className="bg-white rounded-lg shadow-md overflow-x-auto mt-2">
            <table className="w-full table-auto min-w-[600px]">
              <thead>
                <tr className="bg-gray-50 text-left text-sm text-gray-500">
                  <th className="p-3 w-20 font-semibold">Sr. No.</th>
                  <th className="p-3 font-semibold w-80">
                    Project name{" "}
                    {sortWithName ? (
                      <ChevronUp
                        onClick={() => handleSort1()}
                        className="inline-block ml-1"
                        size={20}
                      />
                    ) : (
                      <ChevronDown
                        onClick={() => handleSort()}
                        className="inline-block ml-1"
                        size={20}
                      />
                    )}
                  </th>
                  <th className="p-3 w-45 font-semibold">Ratings</th>
                  <th className="p-3 w-32 font-semibold">View progress</th>
                </tr>
              </thead>
              <tbody>
                {projectList.map((item: any, index: number) => (
                  <tr key={item.id} className="border-t border-gray-200">
                    <td className="p-3 text-sm">
                      {String(index + 1).padStart(2, "0")}
                    </td>
                    <td className="p-3 text-base font-semibold">
                      {item?.name}
                    </td>
                    <td className="p-3 text-base flex flex-row">
                      {item.specs.map((item1: any) => {
                        return (
                          <h3 key={item1.id} className="mr-3">
                            {item1?.value}
                          </h3>
                        );
                      })}
                    </td>

                    <td className="p-3">
                      <button
                        onClick={() =>
                          navigate("/productDetails", {
                            state: {
                              customerName: customerName,
                              projectId: item?.id,
                              projectName: item?.name,
                              internalSrNo: item?.internal_sr_no,
                            },
                          })
                        }
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white text-xs py-1 px-2 rounded"
                      >
                        View
                      </button>
                    </td>
                    <td>
                      <RiDeleteBin6Line
                        color="red"
                        className="cursor-pointer"
                        onClick={() => {
                          setdeleteModal(true), setSelectedProjectId(item?.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
      <Spinner loading={apiLoader} />
      <DeleteModal
        isOpen={deleteModal}
        isClose={(e: boolean) => setdeleteModal(e)}
        onSave={() => deleteProject()}
        text={"Are you sure want to delete the project?"}
      />
    </div>
  );
};

export default customerDetails;
